<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.kostenstelle.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.kostenstelle.list.title"></app-i18n>
      </h1>

      <app-kostenstelle-list-toolbar></app-kostenstelle-list-toolbar>
      <app-kostenstelle-list-filter></app-kostenstelle-list-filter>
      <app-kostenstelle-list-table></app-kostenstelle-list-table>
    </div>
  </div>
</template>

<script>
import KostenstelleListFilter from '@/modules/kostenstelle/components/kostenstelle-list-filter.vue';
import KostenstelleListTable from '@/modules/kostenstelle/components/kostenstelle-list-table.vue';
import KostenstelleListToolbar from '@/modules/kostenstelle/components/kostenstelle-list-toolbar.vue';

export default {
  name: 'app-kostenstelle-list-page',

  components: {
    [KostenstelleListFilter.name]: KostenstelleListFilter,
    [KostenstelleListTable.name]: KostenstelleListTable,
    [KostenstelleListToolbar.name]: KostenstelleListToolbar,
  },
};
</script>

<style></style>
